import { Dispatch } from 'redux'
import { Action, ActionType } from '../types/hashType'

export const updatePlanHash = (key: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_PLAN_HASH,
      payload: key
    })
  }
}

export const updateCouponHash = (key: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_COUPON_HASH,
      payload: key
    })
  }
}

export const updateProductHash = (key: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_PRODUCT_HASH,
      payload: key
    })
  }
}

export const setHashLoaded = (key: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_HASH_LOADED,
      payload: key
    })
  }
}

export const resetHashes = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET
    })
  }
}

export const resetSingleHashes = (key: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET_SINGLE_HASH,
      payload: key
    })
  }
}
