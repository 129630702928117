import { Dispatch } from 'redux'
import { addPlanId } from '../apis/planApi'
import { Action, ActionType, RecipeType } from '../types/planType'

interface PostPlanType {
  planId: string
  planObject: object
  noOfPacks: number | undefined
  packSize: number | undefined
  weeks: number | undefined
  isDefaultPlan: boolean
  packType: string
  fixedOrCustomPlan: string
}

export const postPlanId = ({
  planId,
  planObject,
  noOfPacks,
  packSize,
  weeks,
  isDefaultPlan,
  packType,
  fixedOrCustomPlan
}: PostPlanType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ADD_PLAN_PENDING
    })
    try {
      dispatch({
        type: ActionType.ADD_PLAN_SUCCESS,
        payload: { plan: planObject, noOfPacks, packSize, weeks, isDefaultPlan, packType, fixedOrCustomPlan }
      })
    } catch (_err) {
      dispatch({
        type: ActionType.ADD_PLAN_FAIL
      })
    }
  }
}

export const addRecipes = (recipes: RecipeType[], isSubmit: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ADD_RECIPES_PENDING
    })
    try {
      dispatch({
        type: ActionType.ADD_RECIPES_SUCCESS,
        payload: { recipes, changed: isSubmit }
      })
    } catch (_err) {
      dispatch({
        type: ActionType.ADD_RECIPES_FAIL
      })
    }
  }
}

export const removeRecipes = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.REMOVE_RECIPES_PENDING
    })
    try {
      dispatch({
        type: ActionType.REMOVE_RECIPES_SUCCESS,
        payload: []
      })
    } catch (_err) {
      dispatch({
        type: ActionType.REMOVE_RECIPES_FAIL
      })
    }
  }
}

export const prefillSignupRecipes = (recipes: RecipeType[]) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.PREFILL_SIGNUP_RECIPES,
      payload: recipes
    })
  }
}

export const removeAddons = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.REMOVE_ADDONS_PENDING
    })
    try {
      dispatch({
        type: ActionType.REMOVE_ADDONS_SUCCESS
      })
    } catch (_err) {
      dispatch({
        type: ActionType.REMOVE_ADDONS_FAIL
      })
    }
  }
}

export const updateTrialBox = (trialBoxEnabled: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_TRIAL_BOX_PENDING
    })
    try {
      dispatch({
        type: ActionType.UPDATE_TRIAL_BOX_SUCCESS,
        payload: trialBoxEnabled
      })
    } catch (_err) {
      dispatch({
        type: ActionType.UPDATE_TRIAL_BOX_FAIL
      })
    }
  }
}

export const addAddons = (addons: RecipeType[] | any) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ADD_ADDONS_PENDING
    })
    try {
      dispatch({
        type: ActionType.ADD_ADDONS_SUCCESS,
        payload: addons
      })
    } catch (_err) {
      dispatch({
        type: ActionType.ADD_ADDONS_FAIL
      })
    }
  }
}

export const updateChangedStatus = (status: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_CHANGE_STATUS,
      payload: status
    })
  }
}

export const resetPlan = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET
    })
  }
}
